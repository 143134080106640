import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import SectionTitle from "../../components/Common/SectionTitle";
import EventsShort from "../../components/Events/EventsShort";
import moment from "moment";
// Images
import eventImage from "../../assets/img/event/single.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getConferncesList } from "../../redux/ceoferenceList/actions";

const Events = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConferncesList());
  }, []);

  const { allConferencesItems } = useSelector(
    (state) => state.conferencesListApp
  );

  useEffect(() => {
    AOS.init();
  });
  console.log(allConferencesItems);

  return (
    <div
      className="rs-latest-events style1 bg-wrap pt-100 md-pt-70 md-pb-70"
     
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pr-64 pt-24 md-pt-0 md-pr-14 md-mb-30">
            {/* Section Title Start */}
            <SectionTitle
              sectionClass="sec-title mb-42 md-mb-30 zIndex1"
              subtitleClass="sub-title primary"
              subtitle=" scientific activities"
              titleClass="title mb-0"
              title="Workshops and scientific conferences"
            />
            {/* Section Title End */}
            <div className="single-img">
              <img src={eventImage} alt="Event Image" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="event-wrap">
              {allConferencesItems &&
                allConferencesItems.slice(0, 4).map((conference, i) => (
                  <EventsShort
                    eventsClass="events-short mb-30"
                    dateboxClass={`date-part bgc${i}`}
                    month={moment(conference.dateOfMeeting)
                      .subtract(0, "month")
                      .format("MMMM")}
                    date={moment(conference.dateOfMeeting).date()}
                    cat1={
                      conference.conferenceCategory === "1"
                        ? "conference"
                        : "Workshop"
                    }
                    titleLink={`https://rcc.zu.edu.ly/conferences/${conference.Url}`}
                    // cat2="English"
                    title={conference.Title}
                  />
                ))}

              {/* <EventsShort
                animateDelay="50"
                eventsClass="events-short mb-30"
                dateboxClass="date-part bgc2"
                month="مارس"
                date="17"
                cat1="ورشة عمل"
                title="تنظيم ورشة عمل بعنوان الجودة الواقع والطموح"
              />
              <EventsShort
                animateDelay="100"
                eventsClass="events-short"
                dateboxClass="date-part bgc3"
                month="مارس"
                date="22"
                cat1="مؤتمر علمي"
                title="المؤتمر العلمي الرابع والثاني لقسم التربية وعلم النفس"
              /> */}
              <div className="btn-part mt-54 md-mt-24">
                <a target="_blank" href="https://rcc.zu.edu.ly/">
                  المزيد من الفاعليات العلمية
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
