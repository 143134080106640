import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFacultiesList } from "../../../redux/facultiesList/actions";
import { getDepartmentsList } from "../../../redux/departmentList/actions";

const MenuItems = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacultiesList());
  }, []);
  const { parentMenu, lang, faculty, facultyId } = props;

  useEffect(() => {
    facultyId && dispatch(getDepartmentsList(facultyId));
  }, [facultyId]);

  const location = useLocation();
  const { facultiesItems } = useSelector((state) => state.facultiesListApp);
  const { departmentsItems } = useSelector((state) => state.departmentListApp);

  const medicalFaculty =
    facultiesItems &&
    facultiesItems.filter((fac) => fac.facultycategory === "3");
  const mainSinceFaculty =
    facultiesItems &&
    facultiesItems.filter((fac) => fac.facultycategory === "1");
  const humanFaculty =
    facultiesItems &&
    facultiesItems.filter((fac) => fac.facultycategory === "2");
  return (
    <React.Fragment>
      {/* ****** */}
      {faculty ? (
        <li className={parentMenu === "about" ? " current-menu-item" : ""}>
          <Link
            to={`/faculties/${facultyId}/about`}
            className={
              location.pathname === `/faculties/${facultyId}/about`
                ? "active-menu"
                : ""
            }
          >
            عن الكلية
          </Link>
        </li>
      ) :  <> {lang === 'en' ? '':
        <li className={parentMenu === "about" ? " current-menu-item" : ""}>
          <Link
            to="/about-university"
            className={
              location.pathname === "/about-university" ? "active-menu" : ""
            }
          >
        عن الجامعة
            
          </Link>
        </li>
}
        </>
      }

      {/* ****** */}
      {/* ******** الكليات ******* */}

      {!faculty && (
        <li
          className={
            parentMenu === "faculties"
              ? "rs-mega-menu menu-item-has-children current-menu-item"
              : "rs-mega-menu menu-item-has-children"
          }
        >
          <Link> 
          {lang === 'en' ? 'Faculties' : 'الكليات'}
          
          </Link>
          <ul className="mega-menu">
            <li className="mega-menu-container">
              <div className="single-megamenu">
                <ul className="sub-menu">
                  <li> 
                  {lang === 'en' ? 'Basic and applied sciences' : 'العلوم الاساسية و التطبيقية'}
                      </li>
                  {mainSinceFaculty &&
                    mainSinceFaculty.map((faculty) => (
                      <li>
                        <Link
                          to={`/faculties/${faculty.Url}`}
                          className={
                            location.pathname === `/faculties/${faculty.Url}`
                              ? "active-menu"
                              : ""
                          }
                        >
                          {faculty.facultyTitle}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="single-megamenu">
                <ul className="sub-menu text-right">
                  <li> 
                  {lang === 'en' ? 'Medical and paramedical' : 'الطبية و الطبية المساعدة'}
                    </li>
                  {medicalFaculty &&
                    medicalFaculty.map((faculty) => (
                      <li>
                        <Link
                          to={`/faculties/${faculty.Url}`}
                          className={
                            location.pathname === `/faculties/${faculty.Url}`
                              ? "active-menu"
                              : ""
                          }
                        >
                          {faculty.facultyTitle}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="single-megamenu">
                <ul className="sub-menu">
                  <li> 
                  {lang === 'en' ? 'humanities' : 'العلوم الانسانية'}
                    </li>
                  {humanFaculty &&
                    humanFaculty.map((faculty) => (
                      <li>
                        <Link
                          to={`/faculties/${faculty.Url}`}
                          className={
                            location.pathname === `/faculties/${faculty.Url}`
                              ? "active-menu"
                              : ""
                          }
                        >
                          {faculty.facultyTitle}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </li>
          </ul>
        </li>
      )}
      {/* *************************************** */}
      {/* *********** centers and offices ******* */}
      {!faculty && (
        <li
          className={
            parentMenu === "offices"
              ? "rs-mega-menu menu-item-has-children current-menu-item"
              : "rs-mega-menu menu-item-has-children"
          }
        >
          <Link>
          {lang === 'en' ? 'Organizational Structure' : 'الهيكل  التنظيمي'}
          
          </Link>
          <ul className="mega-menu">
            <li className="mega-menu-container">
              <div className="single-megamenu">
                <ul className="sub-menu">
                  <li> 
                  {lang === 'en' ? 'Offices' : 'المكاتب'}
                    </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://ico.zu.edu.ly/"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'International Cooperation' : 'التعاون الدولي'}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://offices.zu.edu.ly/ucoa"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'University Council Affairs Office' : 'مكتب شؤون مجلس الجامعة'}
                      </a>
                  </li>
                 

                  {/* {humanFaculty &&
                  humanFaculty.map((faculty) => (
                    <li>
                      <Link
                        to={`/faculty/${faculty.Url}`}
                        className={
                          location.pathname === `/faculty/${faculty.Url}`
                            ? "active-menu"
                            : ""
                        }
                      >
                        {faculty.facultyTitle}
                      </Link>
                    </li>
                  ))} */}
                </ul>
              </div>
              <div className="single-megamenu">
                <ul className="sub-menu">
                  <li>
                  {lang === 'en' ? 'Centers' : 'المراكز'}
                    </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://rcc.zu.edu.ly/"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Scientific Research and Consulting Center' : 'مركز البحوث والاستشارات العلمية'}
                      
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://offices.zu.edu.ly/acprcm"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'Plant Research and Complementary Medicine' : 'المركز المتقدم لأبحاث النبات والطب التكميلي'}
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://elc.zu.edu.ly/"
                      className={
                        location.pathname === `/offices` ? "active-menu" : ""
                      }
                    >
                      {lang === 'en' ? 'E-Learning Center' : 'مركز التعليم الإلكتروني'}
                      
                    </a>
                  </li>
                
                  {/* {mainSinceFaculty &&
                  mainSinceFaculty.map((faculty) => (
                    <li>
                      <Link
                        to={`/faculty/${faculty.Url}`}
                        className={
                          location.pathname === `/faculty/${faculty.Url}`
                            ? "active-menu"
                            : ""
                        }
                      >
                        {faculty.facultyTitle}
                      </Link>
                    </li>
                  ))} */}
                </ul>
              </div>

              <div className="single-megamenu">
                <ul className="sub-menu text-right">
                  <li> الادارات</li>
                  {/* {medicalFaculty &&
                  medicalFaculty.map((faculty) => (
                    <li>
                      <Link
                        to={`/faculty/${faculty.Url}`}
                        className={
                          location.pathname === `/faculty/${faculty.Url}`
                            ? "active-menu"
                            : ""
                        }
                      >
                        {faculty.facultyTitle}
                      </Link>
                    </li>
                  ))} */}
                </ul>
              </div>
            </li>
          </ul>
        </li>
      )}
      {!faculty && (
        <>
          <li
            className={parentMenu === "acprograms" ? " current-menu-item" : ""}
          >
            <Link
              to="/acprograms"
              className={
                location.pathname === "/acprograms" ? "active-menu" : ""
              }
            >
              {lang === 'en' ? 'Ac Programs' : ' البرامج الاكاديمية'}
             
            </Link>
          </li>

          <li
            className={
              parentMenu === "department"
                ? "menu-item-has-children current-menu-item"
                : "menu-item-has-children"
            }
          >
            <Link to="#">
              {lang === 'en' ? 'Sc research' : 'البحث العلمي'}
              </Link>
            <ul className="sub-menu">
              <li>
                <a
                  href="https://scholar.google.com/citations?hl=en&view_op=search_authors&mauthors=zu.edu.ly&btnG=&fbclid=IwAR2oPREiw7g_9vwlUd2Hzhf0FQy_ATPFpb7dKrFCVCsv7smf8fB_ZSZ9acU"
                  className={
                    location.pathname === "/department" ? "active-menu" : ""
                  }
                >
                  {" "}
                  {lang === 'en' ? 'Scientific Researcher' : 'الباحث العلمي'}
                  
                </a>
              </li>
              <li>
                <a
                  href="https://journals.zu.edu.ly/"
                  className={
                    location.pathname === "/department"
                      ? "active-menu nav-link"
                      : "nav-link"
                  }
                >
                  {" "}
                  {lang === 'en' ? 'University scientific journals platform' : 'منصة المجلات العلمية بالجامعة'}
                  
                </a>
              </li>
              <li>
                <a
                  href="https://dspace.zu.edu.ly"
                  className={
                    location.pathname === "/department"
                      ? "active-menu nav-link"
                      : "nav-link"
                  }
                >
                  {" "}
                  {lang === 'en' ? 'Digital Repository' : 'المستودع الرقمي'}
                  

                </a>
              </li>
              
              {/* <li>
                <Link
                  to="/"
                  className={
                    location.pathname === "/department" ? "active-menu" : ""
                  }
                >
                  {" "}
                  المؤتمرات العلمية{" "}
                </Link>
              </li> */}
            </ul>
          </li>
        </>
      )}

      {faculty && (
        <li
          className={
            parentMenu === "department"
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link to="#"> اقسام الكلية </Link>
          <ul className="sub-menu">
            {departmentsItems &&
              departmentsItems.map((department) => (
                <li>
                  <Link
                    to={`/department/${department.Url}`}
                    className={
                      location.pathname === "/department"
                        ? "active-menu nav-link"
                        : "nav-link"
                    }
                  >
                    {" "}
                    {department.title}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      )}
      {!faculty ? (
        <li
          className={
            parentMenu === "forms-evidence" ? " current-menu-item" : ""
          }
        >
          <Link
            to="/forms-evidence"
            className={
              location.pathname === "/forms-evidence" ? "active-menu" : ""
            }
          >
             {lang === 'en' ? 'Evidence' : 'الادلة و النماذج'}
            
          </Link>
        </li>
      ) : (
        <li
          className={
            parentMenu === "forms-evidence" ? " current-menu-item" : ""
          }
        >
          <Link
            to={`/faculties/${facultyId}/forms-evidence`}
            className={
              location.pathname === "/forms-evidence" ? "active-menu" : ""
            }
          >
            الادلة و النمادج
          </Link>
        </li>
      )}
  {faculty &&
   <li
   className={
     parentMenu === "forms-evidence" ? " current-menu-item" : ""
   }
 >
   <Link
     to={`/faculties/${facultyId}/members`}
     className={
       location.pathname === "/forms-evidence" ? "active-menu" : ""
     }
   >
    اعضاء هيئة التدريس
   </Link>
 </li> }

      {faculty && (
        <li
          className={
            parentMenu === "advs"
              ? "menu-item-has-children current-menu-item"
              : "menu-item-has-children"
          }
        >
          <Link to="#">الاخبار و الاعلانات</Link>
          <ul className="sub-menu">
            <li>
              <Link
                to={`/faculties/${facultyId}/advs`}
                className={location.pathname === "/advs" ? "active-menu" : ""}
              >
                {" "}
                الاعلانات
              </Link>
            </li>
            <li>
              <Link
                to={`/faculties/${facultyId}/news`}
                className={location.pathname === "/news" ? "active-menu" : ""}
              >
                الاخبار
              </Link>
            </li>
          </ul>
        </li>
      )}

      {/* <li
        className={
          parentMenu === "course"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#" as="#">
          Courses
        </Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/course"
              className={location.pathname === "/course" ? "active-menu" : ""}
            >
              Courses One
            </Link>
          </li>
          <li>
            <Link
              to="/course-2"
              className={location.pathname === "/course-2" ? "active-menu" : ""}
            >
              Courses Two
            </Link>
          </li>
          <li>
            <Link
              to="/course-3"
              className={location.pathname === "/course-3" ? "active-menu" : ""}
            >
              Courses Three
            </Link>
          </li>
          <li>
            <Link
              to="/course-4"
              className={location.pathname === "/course-4" ? "active-menu" : ""}
            >
              Courses Four
            </Link>
          </li>
          <li>
            <Link
              to="/course-5"
              className={location.pathname === "/course-5" ? "active-menu" : ""}
            >
              Courses Five
            </Link>
          </li>
          <li>
            <Link
              to="/course-6"
              className={location.pathname === "/course-6" ? "active-menu" : ""}
            >
              Courses Six
            </Link>
          </li>
          <li>
            <Link
              to="/course/course-single"
              className={
                location.pathname === "/course/course-single"
                  ? "active-menu"
                  : ""
              }
            >
              Courses Single
            </Link>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "pages"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Pages</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "team"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Team
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/team"
                  className={location.pathname === "/team" ? "active-menu" : ""}
                >
                  Team One
                </Link>
              </li>
              <li>
                <Link
                  to="/team-2"
                  className={
                    location.pathname === "/team-2" ? "active-menu" : ""
                  }
                >
                  Team Two
                </Link>
              </li>
              <li>
                <Link
                  to="/team/team-single"
                  className={
                    location.pathname === "/team/team-single"
                      ? "active-menu"
                      : ""
                  }
                >
                  Team Single
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "event"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Event
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/event"
                  className={
                    location.pathname === "/event" ? "active-menu" : ""
                  }
                >
                  Event One
                </Link>
              </li>
              <li>
                <Link
                  to="/event-2"
                  className={
                    location.pathname === "/event-2" ? "active-menu" : ""
                  }
                >
                  Event Two
                </Link>
              </li>
              <li>
                <Link
                  to="/event-3"
                  className={
                    location.pathname === "/event-3" ? "active-menu" : ""
                  }
                >
                  Event Three
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "gallery"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Gallery
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/gallery"
                  className={
                    location.pathname === "/gallery" ? "active-menu" : ""
                  }
                >
                  Gallery One
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery-2"
                  className={
                    location.pathname === "/gallery-2" ? "active-menu" : ""
                  }
                >
                  Gallery Two
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery-3"
                  className={
                    location.pathname === "/gallery-3" ? "active-menu" : ""
                  }
                >
                  Gallery Three
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "shop"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Shop
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/shop"
                  className={location.pathname === "/shop" ? "active-menu" : ""}
                >
                  Shop
                </Link>
              </li>
              <li>
                <Link
                  to="/shop/shop-single"
                  className={
                    location.pathname === "/shop/shop-single"
                      ? "active-menu"
                      : ""
                  }
                >
                  Shop Single
                </Link>
              </li>
              <li>
                <Link
                  to="/shop/cart"
                  className={
                    location.pathname === "/shop/cart" ? "active-menu" : ""
                  }
                >
                  Cart
                </Link>
              </li>
              <li>
                <Link
                  to="/shop/checkout"
                  className={
                    location.pathname === "/shop/checkout" ? "active-menu" : ""
                  }
                >
                  Checkout
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "others"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Others
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/faq"
                  className={location.pathname === "/faq" ? "active-menu" : ""}
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to="/error"
                  className={
                    location.pathname === "/error" ? "active-menu" : ""
                  }
                >
                  404 Page
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  className={
                    location.pathname === "/login" ? "active-menu" : ""
                  }
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className={
                    location.pathname === "/register" ? "active-menu" : ""
                  }
                >
                  Register
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </li> 
      <li
        className={
          parentMenu === "home"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }
      >
        <Link>Home</Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active-menu" : ""}
                  >
                    Main Demo
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-2"
                    className={
                      location.pathname === "/home-2" ? "active-menu" : ""
                    }
                  >
                    Online Course
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-3"
                    className={
                      location.pathname === "/home-3" ? "active-menu" : ""
                    }
                  >
                    University 01
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-4"
                    className={
                      location.pathname === "/home-4" ? "active-menu" : ""
                    }
                  >
                    E-Learning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-5"
                    className={
                      location.pathname === "/home-5" ? "active-menu" : ""
                    }
                  >
                    Distance Learning
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link
                    to="/home-6"
                    className={
                      location.pathname === "/home-6" ? "active-menu" : ""
                    }
                  >
                    Personal Demo
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-7"
                    className={
                      location.pathname === "/home-7" ? "active-menu" : ""
                    }
                  >
                    Online Training
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-8"
                    className={
                      location.pathname === "/home-8" ? "active-menu" : ""
                    }
                  >
                    Online Learning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-10"
                    className={
                      location.pathname === "/home-10" ? "active-menu" : ""
                    }
                  >
                    University 02
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home-12"
                    className={
                      location.pathname === "/home-12" ? "active-menu" : ""
                    }
                  >
                    Freelancing Course
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu text-right">
                <li>الكليات الطبية</li>
                <li>
                  <Link
                    to="/home-14"
                    className={
                      location.pathname === "/home-14" ? "active-menu" : ""
                    }
                  >
                    Courses Archive
                  </Link>
                </li>
                {facultiesItems &&
                  facultiesItems.map((faculty) => (
                    <li>
                      <Link
                        to="/home-9"
                        className={
                          location.pathname === "/home-9" ? "active-menu" : ""
                        }
                      >
                        {faculty.facultyTitle}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </li>
        </ul>
      </li> */}
      {/* ************************ */}
      {/* <li
        className={
          parentMenu === "blog"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Blog</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/blog"
              className={location.pathname === "/blog" ? "active-menu" : ""}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "blogSidebar"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Blog Sidebar
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/blog/blog-left-sidebar"
                  className={
                    location.pathname === "/blog/blog-left-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Blog Left Sidebar
                </Link>
              </li>
              <li>
                <Link
                  to="/blog/blog-right-sidebar"
                  className={
                    location.pathname === "/blog/blog-right-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Blog Right Sidebar
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "blogSingle"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Single Post
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/blog/single-post-left-sidebar"
                  className={
                    location.pathname === "/blog/single-post-left-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Post Left Sidebar
                </Link>
              </li>
              <li>
                <Link
                  to="/blog/single-post-right-sidebar"
                  className={
                    location.pathname === "/blog/single-post-right-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Post Right Sidebar
                </Link>
              </li>
              <li>
                <Link
                  to="/blog/single-post-full-width"
                  className={
                    location.pathname === "/blog/single-post-full-width"
                      ? "active-menu"
                      : ""
                  }
                >
                  Full Width Post
                </Link>
              </li>
            </ul>
          </li>
        </ul>
                </li> */}
      {/* <li
        className={
          parentMenu === "contact"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Contact</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "active-menu" : ""}
            >
              Contact One
            </Link>
          </li>
          <li>
            <Link
              to="/contact-2"
              className={
                location.pathname === "/contact-2" ? "active-menu" : ""
              }
            >
              Contact Two
            </Link>
          </li>
          <li>
            <Link
              to="/contact-3"
              className={
                location.pathname === "/contact-3" ? "active-menu" : ""
              }
            >
              Contact Three
            </Link>
          </li>
          <li>
            <Link
              to="/contact-4"
              className={
                location.pathname === "/contact-4" ? "active-menu" : ""
              }
            >
              Contact Four
            </Link>
          </li>
        </ul>
      </li> */}
    </React.Fragment>
  );
};

export default MenuItems;
